/* VARIABLES */

.type-title {
  font-weight: 700;
  font-size: var(--title-font);
  line-height: var(--title-line);
  letter-spacing: -0.0025em;
}

.type-regular {
  font-weight: 400; /* normal; */
  font-size: var(--regular-font);
  line-height: var(--regular-line);
}

.type-medium {
  font-weight: 500;
  font-size: var(--small-font);
  line-height: var(--small-line);
  letter-spacing: 0.0025em;
}
.type-medium.fz15 {
  font-size: 15px;
  line-height: normal;
}
.type-medium.fz14 {
  font-size: 13px;  /* TODO CLEAN */
  line-height: normal;
}
.type-medium.fz13 {
  font-size: 13px;
  line-height: normal;
}
.type-medium.fz12 {
  font-size: 12px;
  line-height: normal;
}
.type-medium.fz11 {
  font-size: 11px;
  line-height: normal;
}

.type-small {
  font-weight: 400;
  font-size: var(--small-font);
  line-height: var(--small-line);
  letter-spacing: 0.0025em;
}
.type-small.fz11 {
  font-size: 11px;
  line-height: 14px;
}
.type-small.fz12 {
  font-size: 12px;
}

.type-micro {
  font-weight: 400;
  font-size: var(--micro-font);
  line-height: var(--micro-line);
  letter-spacing: 0.005em;
}

.bold {
  font-weight: 700;
}
.medium-bold {
  font-weight: 500;
  font-size: var(--regular-font);
  line-height: var(--regular-line);
}

/* GENERAL */

::-webkit-scrollbar { width: 0px !important; }

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
}
.flexRow.center {
  justify-content: center;
}
.flexRow.space-around {
  justify-content: space-around;
}
.flexRow.space-between {
  justify-content: space-between;
}
.flexRow.wrap {
  flex-wrap: wrap;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.flexRow.center, .flexColumn.center {
  justify-content: center;
}
.flexRow.space-around, .flexColumn.space-around {
  justify-content: space-around;
}
.flexRow.space-between, .flexColumn.space-between {
  justify-content: space-between;
}
.flexRow.align, .flexColumn.align {
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexCenter.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* CARD */

.oo {
  height: 100%;
  position: absolute;
  background: rgba(29, 29, 29, 0.2);
  opacity: 1;
  display: none !important ;
  top: 0;
}

.grid-chart .CardBP:hover, .grid-chart .CardBP.NC, .grid-last-release .CardBP.NC {
  border: 4px solid var(--black-4);
  background: var(--black-4);
  border-radius: 4px;
  margin-top: -4px;
  margin-left: -4px;
}
.grid-chart .CardBP:hover .oo {
  opacity: 0;
}
.grid-chart .CardBP:hover .card-image {
  opacity: 1;
}

.grid-chart.beatx {
  margin-top: 6px;
}
.grid-chart.charts {
  margin-top: -8px;
}
.grid-chart.new-playlists {
    margin-top: -28px;
}

.CardBP .oo {
  position: absolute;
  background: rgba(29, 29, 29, 0.2);
  opacity: 1;
  display: none !important ;
  top: unset;
}

.playlist-page .CardBP .oo {
  background: transparent;
  opacity: 1;
}

.item .oo {
  position: absolute;
  background: rgba(29, 29, 29, 0.2);
  opacity: 1;
  display: none !important ;
  top: 14px;
}

.CardBP:hover .oo, .item:hover .oo, .preview.item .oo {
  display: block !important;
  background: transparent;
  z-index:600;
}

.CardBP:hover .oo.only-cover {
  border-radius: 50px;
}

/* LINK */

.link {
  cursor: pointer;
  z-index: 600;
}

.link-enable {
  cursor: pointer;
  text-decoration: none;
  color: var(--type-secondary);
}

.link-enable.track {
  text-decoration: none;
  font-weight: 400; /* normal; */
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--type-light);
}

.link-enable:hover {
  text-decoration: underline;
  text-decoration-color: var(--type-light);
  color: var(--type-light);
}

.linkage.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: var(--type-secondary);
}


/* PLAYER <=> LIBRARY */

.tag_artist_link, .tag_genre_link, .tag_label_link, .tag_link {
  cursor: pointer;
  text-decoration: none;
  color: #999;
}

.tag_label_link:hover {
  color: #CCC;
}

.song_link1, .song_link2 {
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
}
