.coverflow {
  background: transparent;
}
.coverflow.on {
  background: rgb(17, 17, 17);
  z-index: 1000;
}
.coverflow.off {
  z-index: 0;
}

.cf-fullscreen {
  position: absolute;
  right: 100px;
  top: 6px;
}
.cf-fullscreen:hover .icon-automix {
  stroke: var(--brand-primary);
}

.cf-bar {
  position: absolute;
  z-index: 10;
  width: 100%;
}

.i-mix-title {
  margin-left: 32px;
}

.cf-action {
  margin: 0px 12px;
  cursor: pointer;
  z-index: 50;
}
.cf-action:hover .icon-automix {
  fill: var(--brand-primary);
  stroke: var(--brand-primary);
}

.cf-command {
  margin: 0px 12px;
}
.primary.cf-command:hover {
  color: var(--type-light);
}
.primary.cf-command:hover .cf-icon .icon-automix {
  stroke: var(--type-light);
  fill: var(--type-light);
}
.command.cf-command:hover {
  color: var(--brand-primary);
}
.command.cf-command:hover .cf-icon .icon-automix {
  stroke: var(--brand-primary);
  fill: var(--brand-primary);
}

.cf-icon {
  position: relative;
  top: -4px;
  margin-right: 8px;
}
.cf-icon.left {
  margin: 0px;
  top: -2px;
}
.cf-icon.right {
  margin: 0px;
  top: -2px;
}

.infos.cf-command .cf-icon:hover .icon-automix {
  stroke: var(--brand-primary);
  fill: var(--brand-primary);
}

.bpm-automix {
  width: 45px;
}

.bpm-input {
  width: 22px;
  background: var(--grayscale-600);
  border-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--brand-primary);
  font-size: var(--small-font);
}
.bpm-input::-webkit-outer-spin-button,
.bpm-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bpm-input[type=number] { /* Firefox */
  -moz-appearance: textfield;
}

.cf-button {
  padding: 6px;
  margin: 0px 5px;
  cursor: pointer;
  z-index: 50;
}

.cf-button.titlex {
  background: var(--grayscale-600);
  position: absolute;
  bottom: 16px;
  margin-left: 6px;
}
.cf-button.titlex.start {
  background: var(--brand-secondary);
}
.automix-title:hover {
  color: var(--type-light);
}
.automix-toggle {
  cursor: pointer;
  margin-right: 32px;
}
.cf-button.titlex.full-height {
  left: unset;
  right: unset;
  width: 85%;
}

.block .cf-infos {
  width: 0%;
  opacity: 0;
  justify-content: space-around;
  align-items: flex-start;
  background-color: transparent;
  transition: all 0s linear 0s;
  -webkit-transition: all 0s linear 0s;
  cursor: initial;

  position: absolute;
  top: -400px;
}
.block.active .cf-infos {
  width: 70%;
  opacity: 1;
  background-color: var(--grayscale-500);
  transition: width 550ms ease 0ms, opacity 600ms ease 800ms;
  -webkit-transition: width 550ms ease 0ms, opacity 600ms ease 800ms;

  position: relative;
  top: 0px;
}

.cf-data {
  width: 100%;
}

.cf-title {
  margin: 4px 24px;
  width: 85%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}
.cf-title:hover {
  color: var(--brand-primary);
}

.cf-state {
  margin: 8px 24px;
  color: var(--type-placeholder);
}

.cf-state-nav {
  position: absolute;
  bottom: 53px;
  margin: 0px 32px;
  width: 96%;
}

.cf-state-nav-title {
  z-index: 100;
  margin-bottom: 12px;
}

.cf-state-nav-count {
  position: absolute;
  right: 8px;
  bottom: 14px;
}

.cf-key, .cf-duration {
  right: 0;
  position: absolute;
  margin: 4px 24px;
  cursor: initial;
}

.cf-duration {
  color: var(--type-secondary);
}

.cf-artists {
  margin: 4px 24px;
  color: var(--type-secondary);
  width: 75%;
  text-align: left;
}

.cf-artist {
  color: var(--type-secondary);
}
.cf-artist:hover {
  color: var(--type-light);
}

.block .cf-cover:hover .oo {
  width: 100%;
  display: block !important;
}

.block.active .cf-cover:hover .oo {
  display: none !important ;
}

.cf-cover img {
  width: 100%;
}

.cf-seek {
  position: absolute;
  width: 100%;
  text-align: left;
}

.cf-nav-item {
  border-right: 1px solid var(--grayscale-500);
  cursor: pointer;
  height: 4px;
  background: var(--grayscale-500);
  margin-right: 4px;
}
.cf-nav-item:hover {
  background: var(--grayscale-600);
}
.cf-nav-item.active {
  background: var(--brand-secondary);
}

/* ADD TO PLAYLIST  */

.command.cf-command:hover .cf-icon.add-playlist-button path {
  fill: var(--brand-primary);
}
