.container {
  position: relative;
  background: rgba(0, 0, 0, .1);
  margin: 0 auto;
  padding: 0;
  margin-top: 5px;
  overflow: hidden;
  outline: none;
  background: var(--system-black-2);
}

.coverflow {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
}

.stage {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
}

.figure {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  flex: 0 0 auto;
  cursor: pointer;
  backface-visibility: hidden;
  z-index: 9;
  align-self: center;
}
.figure.animate {
  transition: all 1000ms ease;
}
/* .figure.animate.fast {
  transition: all 500ms ease;
} */

.preloader {
  display: hidden;
}

@keyframes prevent {
  0% { pointer-events: none; }
  100% { pointer-events: auto; }
}
